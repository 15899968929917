import axios from "axios";
import {url} from "./ApiConstant";

export const imageUpload = async (formData) => {
  try {
    const response = await axios.post(`${url}/image-upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 추가
      },
    });
    return response.data;
  } catch (error) {
    console.error('API 요청 실패:', error);
    throw error;
  }
};

export const analyzeImage = async (imageUrl) => {
  try {
    const response = await axios.post(`${url}/analyze-image`, null, {
      params: {
        imageUrl: imageUrl
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // 이는 List<String> 형태의 추천 답변 리스트일 것입니다.
  } catch (error) {
    console.error('이미지 URL 전송 실패:', error);
    throw error;
  }
};
