import React, { useEffect } from 'react';

const GoogleAdSense = () => {
  useEffect(() => {
    // 애드센스 스크립트가 로드된 후 광고를 초기화
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
      <div>
        <ins className="adsbygoogle"
             style={{ display: 'block' }}
             data-ad-client="ca-pub-9256515453297775"
             data-ad-slot="9684803074" // 광고 슬롯 ID로 변경
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
      </div>
  );
}

export default GoogleAdSense;
