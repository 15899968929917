import React from 'react';
import { useEffect, useRef } from 'react';

const KakaoAd = () => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://t1.daumcdn.net/kas/static/ba.min.js';
    script.async = true;
    script.charset = 'utf-8';

    // 스크립트가 로드된 후에 광고를 렌더링
    script.onload = () => {
      // window.kakao_ad 대신 안전한 접근 방식 사용
      if (window.kakao_ad && typeof window.kakao_ad.render === 'function') {
        window.kakao_ad.render();
      }
    };

    if (adContainerRef.current) {
      adContainerRef.current.appendChild(script);
    }

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = '';
      }
    };
  }, []);

  return (
      <div ref={adContainerRef}>
        <ins className="kakao_ad_area"
             style={{ display: 'block' }}
             data-ad-unit="DAN-uSQodv1jblTwgkUU"
             data-ad-width="320"
             data-ad-height="50">
        </ins>
      </div>
  );
};

export default KakaoAd;
