import React, {useEffect, useRef, useState} from 'react';
import {sendMessage} from '../api/LuckyPageApi';
import magic1 from '../images/magic/drawable-hdpi/magic.png';
import magic2 from '../images/magic/drawable-mdpi/magic.png';
import magic3 from '../images/magic/drawable-xhdpi/magic.png';
import magic4 from '../images/magic/drawable-xxhdpi/magic.png';
import magic5 from '../images/magic/drawable-xxxhdpi/magic.png';
import clover from '../images/clover.png';
import heart from '../images/heart.png';
import icBack from "../images/ic_back.png";
import {useNavigate} from "react-router-dom";

const styles = `
  @font-face {
    font-family: 'CustomFont';
    src: url('/assets/fonts/온글잎 김콩해.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    font-family: CustomFont, sans-serif;
    background-color: #000;
    color: #fff;
  }
  .app {
    max-width: 400px;
    height: 800px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .time {
    font-size: 14px;
  }
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  .heart {
    width: 30px;
    height: 30px;
    background-color: #ff69b4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heart::before {
    content: '❤️';
    font-size: 18px;
  }
  .chat-container {
    position: relative;
    background-color: black;
    border-radius: 20px;
    min-height: 460px;
    padding: 20px;
    overflow-y: auto;
  }
  .chat-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 8px;
  }
  .title-description {
    font-size: 20px;
    color: #a6a6a6;
    margin-top: 8px;
    text-align: center;
  }
  .chat-box {
    margin-top: 30px;
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .chat-message {
    text-align: center;
    margin-top: 15px;
    font-size: 23px;
  }
  .input-area {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .editable-input {
    border: none;
    border-radius: 30px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-family: CustomFont, sans-serif;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    padding: 10px 16px;
    min-height: 44px;
    max-height: 200px;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 24px;
  }
  .editable-input div {
    min-height: 24px;
  }
  .editable-input div:not(:last-child)::after {
    content: '';
    display: block;
  }
  .editable-input[contenteditable]:empty::before {
    content: "럭키비키하게 바꾸고 싶은 상황을 적어주세요.";
    color: #a6a6a6;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
  }
  button {
    padding: 15px;
    border: none;
    border-radius: 10px;
    background-color: #161616;
    color: #fff;
    cursor: pointer;
    font-family: CustomFont, sans-serif;
    font-size: 15px;
  }
  .question {
    color: #888;
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  .question-message {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .response-message {
    font-size: 20px;
  }
  .loading {
    text-align: center;
    font-size: 23px;
    animation: wave 1.5s ease-in-out infinite;
  }
  @keyframes wave {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  .line {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #2b2b2b;
  }
  .button-change {
    display: flex;
    align-items: center;
    font-size: 17px;
    justify-content: center;
  }
  .textarea-wrapper {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
    .title-name {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 32px;
  }
  
  button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
`;

const ResponsiveTextarea = ({inputText, setInputText}) => {
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputText(e.target.innerHTML);
    adjustHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');
      range.deleteContents();
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      selection.removeAllRanges();
      selection.addRange(range);
      adjustHeight();
    }
  };

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  return (
      <div
          ref={inputRef}
          className="editable-input"
          contentEditable
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
          role="textbox"
          aria-multiline="true"
      />
  );
};

function LuckyVikiPage() {
  const [inputText, setInputText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleButtonBackIoc = async () => {
    navigate("/main");
  }

  const handleButtonClick = async () => {
    if (!isResponseReceived) {
      setQuestion(inputText);
      setIsLoading(true);

      try {
        const response = await sendMessage(inputText);
        setResponseMessage(response);
        setIsResponseReceived(true);
      } catch (error) {
        setResponseMessage('문제가 발생했어. 다시 시도해줘!');
      } finally {
        setIsLoading(false);
      }
    } else {
      resetState();
    }
  }

  const resetState = () => {
    setInputText('');
    setResponseMessage('');
    setIsResponseReceived(false);
    setQuestion('');
    setIsLoading(false);
  };

  return (
      <>
        <style>{styles}</style>
        <div className="app">
          <header className="header">
            <img
                src={icBack}
                onClick={handleButtonBackIoc}
                alt="icBack" style={{width: '30px', height: '30px'}}/>
            <img src={heart}
                 alt="header" style={{width: '40px', height: '40px'}}/>
          </header>
          <div className="line"></div>
          <div className="chat-container">
            <div className="chat-header">
              <p className="title-name">원영적 사고</p>
              <img
                  src={clover}
                  alt="clover"
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: '0px',
                  }}
              />
            </div>
            <p className="title-description">완전 럭키비키잔앙? 원영적 사고로 럭키비키 경험하기</p>
            <div className="chat-box">
              {!isResponseReceived ? (
                  <>
                    <div className="chat-message">
                      럭키비키하게 바꾸고 싶은<br/>
                      상황을 적어봐!
                    </div>
                    <div className="textarea-wrapper">
                      <ResponsiveTextarea inputText={inputText}
                                          setInputText={setInputText}/>
                    </div>
                  </>
              ) : (
                  <>
                    <div className="question">럭키비키 전 질문</div>
                    <div className="question-message">{question}</div>
                    <div className="question">럭키비키 원영적 사고</div>
                    <div className="response-message">{responseMessage}</div>
                  </>
              )}
              <div className="input-area">
                <button
                    onClick={handleButtonClick}
                    disabled={isLoading || (!isResponseReceived && inputText.trim() === '')}
                >
                  {isLoading ? (
                      "로딩 중..."  // 로딩 중일 때 버튼 텍스트
                  ) : isResponseReceived ? (
                      "럭키비키 다시하기!"  // 응답을 받은 후 버튼 텍스트
                  ) : (
                      <div className="button-change">
                        럭키비키하게 바꾸기 얍!
                        <img
                            src={magic1}
                            srcSet={`
                               ${magic1} 1x,
                               ${magic2} 2x,
                               ${magic3} 3x,
                               ${magic4} 4x,
                               ${magic5} 5x
                             `}
                            alt="Magic"
                            style={{
                              width: '24px',
                              height: '24px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: '10px',
                            }}
                        />
                      </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default LuckyVikiPage;
