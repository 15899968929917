import axios from 'axios';
import {url} from './ApiConstant';

export const sendMessage = async (message) => {
  try {
    const response = await axios.post(`${url}/heejin`, { message });
    return response.data;
  } catch (error) {
    console.error('API 요청 실패:', error);
    throw error;
  }
};
