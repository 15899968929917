import React, {useEffect, useRef, useState} from 'react';
import {imageUpload, analyzeImage} from '../api/KakaoCoachApi';
import chat from '../images/chat.png';
import heart from '../images/heart.png';
import grey_heart from '../images/grey_heart.png';
import plus from '../images/plus.png';
import icBack from "../images/ic_back.png";
import checkIcon from '../images/check-icon.png'; // 체크 아이콘 이미지
import checkedIcon from '../images/checked-icon.png'; // 체크된 아이콘 이미지
import {useNavigate} from "react-router-dom";

const styles = `
  @font-face {
    font-family: 'CustomFont';
    src: url('/assets/fonts/온글잎 김콩해.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    font-family: CustomFont, sans-serif;
    background-color: #000;
    color: #fff;
  }
  .app {
    max-width: 400px;
    height: 800px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .time {
    font-size: 14px;
  }
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  .heart {
    width: 30px;
    height: 30px;
    background-color: #ff69b4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heart::before {
    content: '❤️';
    font-size: 18px;
  }
  .chat-container {
    position: relative;
    background-color: black;
    border-radius: 20px;
    padding: 20px;
    overflow-y: auto;
  }
  .chat-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 8px;
  }
  .title-description {
    font-size: 20px;
    color: #a6a6a6;
    margin-top: 8px;
    text-align: center;
  }
  .chat-box {
    margin-top: 30px;
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 10px;
  }
  .chat-message {
    text-align: center;
    margin-top: 15px;
    font-size: 23px;
  }
  .chat-message img {
  cursor: pointer;
}
  .input-area {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 25px;
  }
  .editable-input {
    border: none;
    border-radius: 30px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-family: CustomFont, sans-serif;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    padding: 10px 16px;
    min-height: 44px;
    max-height: 200px;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 24px;
  }
  .editable-input div {
    min-height: 24px;
  }
  .editable-input div:not(:last-child)::after {
    content: '';
    display: block;
  }
  .editable-input[contenteditable]:empty::before {
    content: "럭키비키하게 바꾸고 싶은 상황을 적어주세요.";
    color: #a6a6a6;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
  }
button {
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: #2b2b2b;
  color: #fff;
  cursor: pointer;
  font-family: CustomFont, sans-serif;
  font-size: 15px;
  margin-bottom: 40px;
  transition: color 0.3s ease; /* 색상 변경에 애니메이션 효과 추가 */
}
  .question {
    color: #888;
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  .question-message {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .response-message {
    font-size: 20px;
  }
  .loading {
    text-align: center;
    font-size: 23px;
    animation: wave 1.5s ease-in-out infinite;
  }
  @keyframes wave {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  .line {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #2b2b2b;
  }
  .button-change {
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }
  .textarea-wrapper {
    position: relative;
    margin-top: 15px;
    
  }
    .title-name {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 32px;
  }
  .input_talk {
    font-size: 24px;
    margin: 0;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .input_talk_2 {
    font-size: 18px;
    color: #a6a6a6;
    margin: 0;
    text-align: center;
    margin-top: 15px;
  }
  
button:disabled {
  color: #000; /* 비활성화 시 글자 색을 검정색으로 변경 */
  cursor: not-allowed;
  opacity: 1; /* 불투명도를 1로 설정하여 배경 유지 */
}

.response-message-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #000;
  align-items: flex-end;
  max-width: calc(100% - 40px); /* 양쪽에 20px 여백 */
  margin: 0 auto; /* 중앙 정렬 */
  margin-top: 15px;
}


.message-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; /* 이 줄을 추가 */
  margin-bottom: 1px;
  max-width: 200px;
  position: relative;
}

.message-bubble {
  background-color: #fff;
  border-radius: 12px 12px 0 12px;
  padding: 12px;
  padding-right: 15px;
  width: 100%;
  word-wrap: break-word;
}

.message {
  color: #000;
  margin: 0;
  font-size: 16px;
}

.copy-button {
  bottom: 0;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.copy-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0;
  margin-left: 10px;
}

.copy-notification {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0 20px;
  border-radius: 0; /* 기본 border-radius를 0으로 설정 */
  border-top-left-radius: 10px; /* 왼쪽 상단 모서리 */
  border-top-right-radius: 10px; /* 오른쪽 상단 모서리 */
  border-bottom-left-radius: 10px; /* 왼쪽 하단 모서리 */
  border-bottom-right-radius: 10px; /* 오른쪽 하단 모서리 */
  width: calc(100% - 60px);
  max-width: 360px;
  text-align: center;
  font-size: 20px;
  border: 2px solid rgb(255, 255, 255); /* RGB 값으로 흰색 지정 */
  box-shadow: 0 2px 10px rgba(0, 0, 0);
  animation: fadeIn 0.3s, fadeOut 0.3s 1.7s;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
`;

const ResponsiveTextarea = ({inputText, setInputText}) => {
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputText(e.target.innerHTML);
    adjustHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');
      range.deleteContents();
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      selection.removeAllRanges();
      selection.addRange(range);
      adjustHeight();
    }
  };

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  return (
      <div
          ref={inputRef}
          className="editable-input"
          contentEditable
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
          role="textbox"
          aria-multiline="true"
      />
  );
};

function KakaoCoach() {
  const [inputText, setInputText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setShowNotification(true);
      setTimeout(() => {
        setCopiedIndex(null);
        setShowNotification(false);
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleButtonBackIoc = async () => {
    navigate("/main");
  }

  const handleAnalyzeImage = async () => {
    if (!isResponseReceived) {
      setQuestion(inputText);
      setIsLoading(true);

      try {
        const response = await analyzeImage(imageUrl);
        setResponseMessage(response);
        setIsResponseReceived(true);
      } catch (error) {
        setResponseMessage('문제가 발생했어. 다시 시도해줘!');
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleImageChange = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        setImage(file);
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);

        // 이미지를 즉시 업로드
        await handleImageUpload(file);
      }
    };
    input.click();
  };

  const handleImageUpload = async (file) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    setIsLoading(true);
    try {
      const response = await imageUpload(formData);
      setImageUrl(response);
    } catch (error) {
      console.error('Error uploading image:', error);
      setResponseMessage('오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  const CopyNotification = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
        <div className="copy-notification">
          복사가 완료되었습니다.
        </div>
    );
  };

  const resetState = () => {
    setInputText('');
    setResponseMessage('');
    setIsResponseReceived(false);
    setQuestion('');
    setIsLoading(false);
    setImage(null);
    setPreviewUrl(null);
    setImageUrl('');
  };

  return (
      <>
        <style>{styles}</style>
        <div className="app">
          <header className="header">
            <img
                src={icBack}
                onClick={handleButtonBackIoc}
                alt="icBack" style={{width: '30px', height: '30px'}}/>
            <img src={heart}
                 alt="header" style={{width: '40px', height: '40px'}}/>
          </header>
          <div className="line"></div>
          <div className="chat-container">
            <div className="chat-header">
              {!isResponseReceived ? (
                  <p className="title-name">카톡 답장 코칭 받기</p>
              ) : (
                  <p className="title-name">코칭 결과 도착</p>
              )}

              <img
                  src={chat}
                  alt="chat"
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: '0px',
                  }}
              />
            </div>
            <p className="title-description">썸남/썸녀에게 어떻게 답장해야 할지 모르겠다면?</p>


              {!isResponseReceived ? (
                  <>
                  <div className="chat-box">
                    <div className="chat-message">
                      {previewUrl ? (
                          <img
                              src={previewUrl}
                              alt="Preview"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                          />
                      ) : (
                          <div className="chat-message">
                            <img
                                src={plus}
                                alt="plus"
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer',
                                  marginBottom: '20px',
                                }}
                                onClick={handleImageChange}
                            />

                            <div className="chat-response-message">
                              <p className="input_talk">카톡 대화 스크린샷을</p>
                              <p className="input_talk">업로드해 주세요.</p>

                              <p className="input_talk_2">상대방의 바로 전의 대화 내용을 포함해
                                주세요.</p>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>

                  </>
              ) : (
                  <>
                    <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                    />
                    <div className="response-message-list">
                      {responseMessage.map((message, index) => (
                          <div key={index} className="message-container">
                            <div className="message-bubble">
                              <p className="message">{message.replace(/^"|"$/g, '')}</p>
                            </div>
                            <button
                                className="copy-button"
                                onClick={() => handleCopy(message.replace(/^"|"$/g, ''), index)}
                            >
                              <img
                                  src={copiedIndex === index ? checkedIcon : checkIcon}
                                  alt="Copy"
                                  className="copy-icon"
                              />
                            </button>
                          </div>
                      ))}
                    </div>
                    <CopyNotification isVisible={showNotification} />
                  </>
              )}

            </div>

            <div className="input-area">
              <button
                  disabled={isLoading || (!isResponseReceived && !image)}
              >
                {isLoading ? (
                    <div className="button-change">
                      성공률 99% 답장 만드는 중...
                      <img
                          src={grey_heart}
                          alt="Magic"
                          style={{
                            width: '28px',
                            height: '28px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px',
                          }}
                      />
                    </div>
                ) : isResponseReceived ? (
                    <div className="button-change" onClick={resetState}>
                      성공률 99% 답장 다시 받기
                      <img
                          src={grey_heart}
                          alt="Magic"
                          style={{
                            width: '28px',
                            height: '28px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px',
                          }}
                      />
                    </div>
                ) : (
                    <div className="button-change" onClick={handleAnalyzeImage}>
                      성공률 99% 답장 코칭 받기
                      <img
                          src={grey_heart}
                          alt="Magic"
                          style={{
                            width: '28px',
                            height: '28px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px',
                          }}
                      />
                    </div>
                )}
              </button>
          </div>
        </div>
      </>
  );
}

export default KakaoCoach;
