import React, {useEffect, useRef, useState} from 'react';
import magic1 from '../images/magic/drawable-hdpi/magic.png';
import magic2 from '../images/magic/drawable-mdpi/magic.png';
import magic3 from '../images/magic/drawable-xhdpi/magic.png';
import magic4 from '../images/magic/drawable-xxhdpi/magic.png';
import magic5 from '../images/magic/drawable-xxxhdpi/magic.png';
import clover from '../images/clover.png';
import new_badge from '../images/new.png';
import chat from '../images/chat.png';
import heart from '../images/heart.png';
import logo2 from '../images/logo/logo.svg';
import icNext from '../images/ic_next.png';
import onComming from '../images/Oncoming_fist.png';
import Billed from '../images/Billed cap.png';
import cake from '../images/Shortcake.png';
import chocolate from '../images/Chocolate bar.png';
import {useNavigate} from "react-router-dom";

const styles = `
  @font-face {
    font-family: 'CustomFont';
    src: url('/assets/fonts/온글잎 김콩해.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-weight: lighter !important
  }

  body {
    margin: 0;
    font-family: CustomFont, sans-serif;
    font-weight: lighter !important;
    background-color: #000;
    color: #fff;
  }
  .app {
    max-width: 400px;
    height: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .time {
    font-size: 14px;
  }
  h1 {
    margin: 0;
    font-size: 24px;
  }
  .heart {
    width: 30px;
    height: 30px;
    background-color: #ff69b4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heart::before {
    content: '❤️';
    font-size: 18px;
  }
  .chat-container {
    position: relative;
    background-color: black;
    border-radius: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .chat-header {
    text-align: center;
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }
  .chat-header h2 {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 32px;
  }
  .chat-header p {
    // margin: 5px 0 0;
    // font-size: 20px;
    // color: #888;
  }
  .title-description {
    font-size: 16px;
    color: #a6a6a6;
    margin-top: 8px;
    margin-left: 10px;
  }
  .chat-box {
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 15px;
  }
  .chat-box2 {
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 15px;
  }
  chat-box-tira {
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .chat-box3 {
  margin-top: 30px;
    background-color: #2b2b2b;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 15px;
  }
  .chat-message {
    text-align: center;
    margin-top: 15px;
    font-size: 23px;
  }
  .input-area {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 15px;
  }
  .editable-input {
    border: none;
    border-radius: 30px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-family: CustomFont, sans-serif;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    padding: 10px 16px;
    min-height: 44px;
    max-height: 200px;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 24px;
  }
  .editable-input div {
    min-height: 24px;
  }
  .editable-input div:not(:last-child)::after {
    content: '';
    display: block;
  }
  .editable-input[contenteditable]:empty::before {
    content: "럭키비키하게 바꾸고 싶은 상황을 적어주세요.";
    color: #a6a6a6;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
  }
  button {
    padding: 13px;
    border: none;
    border-radius: 10px;
    background-color: #161616;
    color: #fff;
    cursor: pointer;
    font-family: CustomFont, sans-serif;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .question {
    color: #888;
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  .question-message {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .response-message {
    font-size: 20px;
  }
  .loading {
    text-align: center;
    font-size: 23px;
    animation: wave 1.5s ease-in-out infinite;
  }
  @keyframes wave {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  .line {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #2b2b2b;
  }
  .button-chage {
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
  }
  .textarea-wrapper {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .chat-header-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .title-name {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 24px;
  }
`;

function MainPage() {
  const navigate = useNavigate();

  const routeHeejinView = async () => {
    navigate('/heejin');
  }

  const reouteLuckyView = async () => {
    navigate('/lucky');
  }

  const routeTiraMisu = async () => {
    navigate('/tiraMisu');
  }

  const routeKakaocoach = async () => {
    navigate('/kakaoCoach');
  }

  return (
      <>
        <style>{styles}</style>
        <div className="app">
          <header className="header">
            <img
                src={logo2}
                alt="logo" style={{ width: '96px', height: '50px' }} />
            <img src={heart}
                 alt="header" style={{ width: '40px', height: '40px' }}/>
          </header>
          <div className="line"></div>

          <div className="chat-container">
            <div className="chat-box3" onClick={routeKakaocoach}>
              <div className="chat-header">
                <div className="chat-header-left">
                  <img
                      src={new_badge}
                      alt="new_badge"
                      style={{
                        width: '42px',
                        height: '22px',
                        marginRight: '5px',
                      }}
                  />
                  <p className="title-name">카톡 답장 코칭받기</p>
                  <img
                      src={chat}
                      alt="chat"
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                  />
                </div>
              </div>
              <p className="title-description">썸남/썸녀에게 어떻게 답장해야 할지 모르겠다면?</p>
              <div className="input-area">
                <button>
                  <div className="button-chage">
                    성공률 99% 답장 코칭받기
                    <img
                        src={icNext}
                        alt="icNext"
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '0px',
                          marginLeft: '5px',
                        }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>


          <div className="chat-container">
            <div className="chat-box2" onClick={routeTiraMisu}>
              <div className="chat-header">
                <div className="chat-header-left">
                  <p className="title-name">T라 미숙해</p>
                  <img
                      src={cake}
                      alt="cake"
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                  />
                </div>
              </div>
              <p className="title-description">T라 미숙하다면? F적 사고 경험하기</p>
              <div className="input-area">
                <button>
                  <div className="button-chage">
                    달콤한 티라미수 케잌으로 바꾸기!
                    <img
                        src={icNext}
                        alt="icNext"
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '0px',
                          marginLeft: '5px',
                        }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="chat-container">
            <div className="chat-box" onClick={reouteLuckyView}>
              <div className="chat-header">
                <div className="chat-header-left">
                  <p className="title-name">원영적 사고</p>
                  <img
                      src={clover}
                      alt="clover"
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '0px',
                      }}
                  />
                </div>
              </div>
              <p className="title-description">완전 럭키비키잔앙? 원영적 사고 경험하기</p>
              <div className="input-area">
                <button>
                  <div className="button-chage">
                    럭키비키하게 바꾸기 얍!
                    <img
                        src={icNext}
                        alt="icNext"
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '0px',
                          marginLeft: '5px',
                        }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>


          <div className="chat-container">
            <div className="chat-box2" onClick={routeHeejinView}>
              <div className="chat-header">
                <div className="chat-header-left">
                  <p className="title-name">희진적 사고</p>
                  <img
                      src={Billed}
                      alt="Billed"
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '0px',
                      }}
                  />
                </div>
              </div>
              <p className="title-description">맞다이로 들어와! 희진적 사고 경험하기</p>
              <div className="input-area">
                <button>
                  <div className="button-chage">
                    맞다이로 들어와!
                    <img
                        src={icNext}
                        alt="icNext"
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '0px',
                          marginLeft: '5px',
                        }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default MainPage;
