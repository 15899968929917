import { Routes, Route } from 'react-router-dom';
import LuckyVikiPage from './page/LuckyVikiPage';
import KakaoAd from "./page/KakaoAd";
import GoogleAdSense from "./page/GoogleAdSense";
import HeejinPage from "./page/HeejinPage";
import MainPage from "./page/MainPage";
import TiraMisuPage from "./page/TiraMisuPage";
import KakaoCoach from "./page/KakaoCoach";

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <KakaoAd />
          <Routes>
            <Route path="/lucky" element={<LuckyVikiPage />} />
            <Route path="/heejin" element={<HeejinPage />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/tiraMisu" element={<TiraMisuPage />} />
            <Route path="/kakaoCoach" element={<KakaoCoach />} />
          </Routes>
        </header>
      </div>
  );
}

export default App;
